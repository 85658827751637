<div class="rounded-lg p-4 md:relative md:max-w-md">
  <div class="items-center md:flex">
    <div
      [class]="
        ' mx-auto flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 text-yellow-400 ' +
        (data.iconPanelClass || '')
      "
      [innerHTML]="data.icon || defaultIcon"></div>
    <div class="mt-4 flex-grow pb-6 text-center md:mt-0 md:pl-4 md:text-left">
      <p class="font-bold">{{ data.title }}</p>
      @if (data.body) {
        <p
          class="mt-1 pl-1 text-sm font-normal text-gray-700"
          [innerHTML]="data.body"></p>
      }
    </div>
  </div>
  <div class="gap-4 text-center md:flex md:justify-end md:text-right">
    <button
      [class]="
        'block w-full cursor-pointer rounded-lg bg-primary-400 px-4 py-3 text-sm font-semibold text-secondary md:ml-2 md:inline-block md:w-auto md:py-1 ' +
        (data.confirmButtonClass || '')
      "
      [mat-dialog-close]="true">
      {{ data.confirmCaption || 'Confirm' }}
    </button>
    <button
      [class]="
        ' mt-4 block w-full cursor-pointer rounded-lg bg-accent-400 px-4 py-3 text-sm font-semibold text-secondary md:mt-0 md:inline-block md:w-auto md:py-1 ' +
        data.cancelButtonClass
      "
      [mat-dialog-close]="false">
      {{ data.cancelCaption || 'Close' }}
    </button>
  </div>
</div>
